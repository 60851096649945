import { useStaticQuery, graphql } from 'gatsby'

export default function usePrimaries(name?: string) {
	if (!name) {
		return null
	}
	const { allFile } = useStaticQuery<GatsbyTypes.PrimariesQuery>(
		graphql`
			query Primaries {
				allFile(filter: {relativePath: {glob: "**/primaries/**.*"}}) {
					nodes {
						name
						publicURL
					}
				}
			}
		`
	)
	return allFile.nodes.find(node => node.name === name)
}
