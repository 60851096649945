import React from 'react'
import useSiteMetadata from './Hooks/useSiteMetadata'
import styled from 'styled-components'

const Div = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	grid-column-gap: 1em;
	align-items: center;
	margin: 2em auto 0;
	text-align: center;
	@media only screen and (max-width: 600px) {
		a:last-child {
			display: none;
		}
	}
`

export default function Downloads() {
	const { appID, appName } = useSiteMetadata() ?? {}
	return (
		<Div>
			<a href={`https://apps.apple.com/app/id${appID}#?platform=iphone`}>
				<img src="/appstore.svg" alt={`Download ${appName} on the App Store`} />
			</a>
			<a href={`https://apps.apple.com/app/id${appID}#?platform=mac`}>
				<img src="/appstore-mac.svg" alt={`Download ${appName} on the Mac App Store`} />
			</a>
		</Div>
	)
}
