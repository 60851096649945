import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import useSiteMetadata from '../Hooks/useSiteMetadata'
import { Link } from 'gatsby'
import { barStyle, constants } from '../Styles'
import styled from 'styled-components'

const Nav = styled.nav`
	${barStyle}
	border-bottom: ${constants.border};
	margin-bottom: 3em;
	& > span {
		a, a:visited {
			font-weight: 800;
			color: var(--headingColor);
			display: grid;
			grid-template-columns: 2em 1fr;
			grid-column-gap: 0.25em;
			align-items: center;
			filter: grayscale(1);
			opacity: 0.6;
		}
		a:hover {
			filter: none;
			opacity: 1;
		}
	}
	ul {
		font-family: ${constants.fontStack};
		display: grid;
		font-size: 0.8em;
		font-weight: 600;
		grid-auto-flow: column;
		grid-gap: 1em;
		justify-items: end;
		align-items: center;
		margin-left: auto;
		margin-right: -0.75em;
	}
	li {
		[aria-current] {
			color: var(--accentColor);
		}
		a, a:visited {
			border-radius: ${constants.borderRadius};
			padding: 0.6em 0.75em;
		}
		a:hover, a:active, [aria-current] {
			background-color: var(--linkBackgroundColor);
		}
	}
`

export default function Navigation() {
	const siteMetadata = useSiteMetadata()
	const appName = siteMetadata?.appName
	return (
		<Nav>
			<span>
				<Link to="/">
					<StaticImage
						src="../../images/icon.png"
						width={40}
						placeholder="none"
						quality={80}
						alt=""
					/>
					{appName}
				</Link>
			</span>
			<ul>
				<li><Link to="/release-notes/">Release Notes</Link></li>
			</ul>
		</Nav>
	)
}
