import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import useSiteMetadata from './Hooks/useSiteMetadata'
import styled from 'styled-components'

const Link = styled.a`
	display: grid;
	grid-template-columns: 40px 1fr;
	grid-column-gap: 0.5em;
	align-items: center;
	margin: 1em auto 0;
	padding: 1em;
	background-color: var(--linkBackgroundColor);
	border-radius: 1em;
	&, &:visited, &:hover {
		color: black !important;
	}
`

export default function BetaInvite() {
	const { appName, testFlight } = useSiteMetadata() ?? {}
	return (
		testFlight &&
		<Link href={testFlight}>
			<StaticImage src="../images/icon.png" width={40} placeholder="none" alt="" />
			{`Join the ${appName} beta test 👈`}
		</Link>
	)
}
