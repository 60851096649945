import { useStaticQuery, graphql } from 'gatsby'

export default function useSiteMetadata() {
	const { site } = useStaticQuery<GatsbyTypes.SiteMetaDataQuery>(
		graphql`
			query SiteMetaData {
				site {
					siteMetadata {
						appName
						appID
						title
						description
						siteUrl
						twitterUsername
						testFlight
					}
				}
			}
		`
	)
	return site?.siteMetadata
}
