import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import AppHeader from './AppHeader'
import AppFeature from './AppFeature'
import BetaInvite from './BetaInvite'
import Downloads from './Downloads'
import Navigation from './Navigation/Navigation'
import Footer from './Navigation/Footer'
import SEO from './SEO'
import styled, { createGlobalStyle } from 'styled-components'
import { Reset } from 'styled-reset'
import { constants, fullWidth, lightTheme } from './Styles'

const GlobalStyle = createGlobalStyle`
	${lightTheme}
	body {
		margin: 0;
		padding: 0;
		background-color: var(--backgroundColor);
	}
`
const Container = styled.div`
	color: var(--contentColor);
	font-family: ${constants.fontStack};
	font-size: 20px;
	@media only screen and (max-width: 600px) {
		font-size: 16px;
	}
	max-width: ${constants.containerWidth};
	margin: 0 auto;
	padding: 0 1em;
	main {
		display: grid;
		align-items: center;
		& > h1 {
			margin-bottom: 2rem;
		}
		p, ul, ol {
			margin: 1em 0 0;
		}
		a {
			color: var(--accentColor);
			&:hover {
				opacity: 0.66;
			}
		}
		time {
			display: block;
			margin: 0.5em 0 0;
			opacity: 0.5;
		}
		strong {
			font-weight: 800;
		}
		ul {
			margin-block-start: 0;
			padding-inline-start: 1em;
			li {
				list-style-type: disc;
			}
		}
		table, th, td {
			border: 1px solid black;
			padding: 1em;
		}
	}
	*:not(.press > p) > .gatsby-resp-image-wrapper {
		${fullWidth}
		margin-top: 1em !important;
	}
	.press > p > .gatsby-resp-image-wrapper {
		width: 100% !important;
	}
	.press > p {
		display: grid;
		grid-gap: 1em;
		grid-template-columns: 1fr 1fr;
		grid-auto-flow: column;
	}
	h1, h2, h3, h4, h5, h6 {
		font-family: ${constants.headingFontStack};
		color: var(--headingColor);
		font-weight: 800;
		margin: 1em 0 0;
		line-height: 1;
	}
	h1 {
		font-size: 3em;
		margin-top: 0;
		text-align: center;
	}
	h2 {
		font-size: 1.5em;
	}
	h3 {
		font-size: 1.3em;
	}
	h4 {
		font-size: 1.2em;
	}
	p, li {
		line-height: 1.4;
	}
	a {
		text-decoration: none;
		transition: color 150ms ease-out, opacity 150ms ease-out, background 150ms ease-out;
	}
	.gatsby-image-wrapper [data-main-image] {
		transition: none !important;
	}
`

export default function Layout(props: { children: JSX.Element; pageContext }) {
	const shortcodes = { AppHeader, AppFeature, BetaInvite, Downloads } // components available in MDX
	const { title, description, noindex } = props.pageContext?.frontmatter ?? {}
	return (
		<MDXProvider components={shortcodes}>
			<Reset />
			<GlobalStyle />
			<Container>
				<SEO title={title} description={description} noindex={noindex} />
				<Navigation/>
				<main>
					{ props.children }
				</main>
				<Footer />
			</Container>
		</MDXProvider>
	)
}
