import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import usePrimaries from './Hooks/usePrimaries'
import useScreenshot from './Hooks/useScreenshot'
import miniOverlay from '../images/mini-right-angle.png'
import miniOverlayLeft from '../images/mini-left-angle.png'
import { constants } from './Styles'

const Div = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 3em;
	align-items: center;
	margin-bottom: 3em;
	@media (max-width: ${constants.largeBreakpoint}) {
		grid-template-columns: auto;
	}
	&.flipped {
		@media (min-width: ${constants.largeBreakpoint}) {
			& > div {
				order: 1;
			}
			grid-template-columns: auto 1fr;
		}
	}
	img {
		height: auto;
		user-drag: none;
		user-select: none;
	}
	span.title {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-column-gap: 0.5em;
		align-items: center;
		margin: 2em 0 0;
		@media (min-width: ${constants.largeBreakpoint}) {
			margin: 2em 0 0 calc(-36px - 0.55em);
		}
		img {
			width: 1.8em;
		}
		h2 {
			margin: 0;
		}
	}
	span.phone {
		margin: 3em auto -1em;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	}
	.screenshot {
		grid-area: 1 / 1 / 2 / 2;
		border-radius: 8px;
		width: 235px;
		height: 386px;
		z-index: -2;
		&.right {
			transform: skew(-20.9deg, 0);
		}
		&.left {
			transform: skew(20.9deg, 0);
		}
	}
	.overlay {
		grid-area: 1 / 1 / 2 / 2;
		width: 400px;
		height: auto;
		z-index: -1;
		&.right {
			margin: -8px -86.5px 0 -78px;
		}
		&.left {
			margin: -8px -2em 0 -86.5px;
		}
		@media (max-width: ${constants.largeBreakpoint}) {
			&.right {
				margin-right: -1em;
			}
		}
	}
`

export default function AppFeature(
	props: {
		children?: JSX.Element;
		title: string;
		iconName?: string;
		screenshot?: string;
		flip?: boolean;
	}
) {
	const icon = usePrimaries(props.iconName)
	return (
		<Div className={props.flip ? 'flipped' : undefined}>
			<div>
				<span className="title">
					{ icon &&
						<img src={icon.publicURL} alt="" width="36px" height="36px" loading="lazy" />
					}
					<h2>{props.title}</h2>
				</span>
				{props.children}
			</div>
			<AngledPhone screenshot={props.screenshot} flip={props.flip} />
		</Div>
	)
}

function AngledPhone(props: { screenshot?: string; flip?: boolean }) {
	const screenshot = useScreenshot(props.screenshot)?.childImageSharp?.gatsbyImageData
	const overlay = props.flip ? miniOverlayLeft : miniOverlay
	const side = props.flip ? 'left' : 'right'
	if (!screenshot) {
		return null
	}
	return (
		<span className="phone">
			<GatsbyImage image={screenshot} className={`screenshot ${side}`} alt="" loading="lazy" />
			<img src={overlay} className={`overlay ${side}`} alt="" loading="lazy" />
		</span>
	)
}
