import { useStaticQuery, graphql } from 'gatsby'

export default function useScreenshot(name?: string) {
	if (!name) {
		return null
	}
	const { allFile } = useStaticQuery<GatsbyTypes.ScreenshotQuery>(
		graphql`
			query Screenshot {
				allFile(filter: {relativePath: {glob: "**/screenshots/**.*"}}) {
					nodes {
						name
						childImageSharp {
							gatsbyImageData(
								placeholder: NONE
								width: 235
								height: 386
								transformOptions: {fit: FILL}
								quality: 80
							)
						}
					}
				}
			}
		`
	)
	return allFile.nodes.find(node => node.name === name)
}
