import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import useSiteMetadata from './Hooks/useSiteMetadata'
import styled from 'styled-components'

const Header = styled.div`
	display: grid;
	& > div {
		display: grid;
		grid-template-columns: 4.6em 1fr;
		grid-column-gap: 1em;
		align-items: center;
		margin: 0 auto;
		text-align: center;
	}
	.description {
		max-width: 500px;
		margin: 2em auto 0;
		text-align: center;
	}
`

export default function AppHeader(props: { description?: string }) {
	const { appName } = useSiteMetadata() ?? {}
	return (
		<Header>
			<div>
				<StaticImage
					src="../images/icon.png"
					width={92}
					placeholder="none"
					quality={80}
					alt=""
				/>
				<h1>{appName}</h1>
			</div>
			{ props.description &&
				<p className="description">{props.description}</p>
			}
		</Header>
	)
}
